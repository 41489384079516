.header-search {
    min-width: 18rem !important;
}

.header {
    background: $white;
    padding-top: .75rem;
    padding-bottom: .75rem;
    border-bottom: 1px solid $border;
}

@media print {
    .header {
        display: none;
    }
}

.header {
    .dropdown-menu {
        margin-top: 0;
    }
    .mega-menu {
        width: 350px;
    }
    .nav-tabs {
        margin: 0;
    }
}

.header-right-icons {
    a {
        padding-top: 9px;
    }
    .dropdown-menu a {
        padding: 12px;
    }
    .profile-1 a {
        padding-top: 5px;
    }
}

.header-brand {
    color: inherit;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.6rem;
}

.app-header .header-brand {
    color: inherit;
    margin-right: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    min-width: 200px;
    text-align: center;
    .header-brand-img {
        margin-right: 0;
    }
}

.header-brand:hover {
    color: inherit;
    text-decoration: none;
}

.header-brand-img {
    line-height: 2rem;
    vertical-align: middle;
    width: auto;
}

.header-avatar {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: bottom;
    border-radius: 50%;
}

.header-btn {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1rem;
}

@media (max-width: 991px) {
    .header .form-inline {
        margin-right: -2px;
    }
}

@media (max-width: 991px) {
    .sidebar-mini.sidenav-toggled {
        .side-header {
            display: none;
        }
    }
    .header.hor-header {
        position: fixed;
        border-bottom: 1px solid $border;
        width: 100%;
        z-index: 999;
        top: 0;
    }
    .header.hor-header {
        .header-brand-img {
            &.light-logo {
                display: none;
            }
            &.desktop-logo {
                display: none;
                margin: 0 auto;
            }
            &.light-logo1 {
                display: block;
            }
        }
        .header-brand1 {
            width: 100%;
            margin: 0 auto;
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            margin-top: 0 !important;
        }
    }
}
@media (max-width: 991px) {
    .header-brand-img {
        margin-left: 1.5rem;
        margin: 3px auto;
    }
    .side-header {
        display: none !important;
    }
}

@media (max-width: 992px) and (min-width: 768px) {
    .animated-arrow {
        cursor: pointer;
        padding: 25px 35px 16px 0px !important;
        margin: 11px 0 0 15px;
    }
}

@media (min-width: 768px) {
    .app-content .side-app {
        padding-top: 0px !important;
    }
}

.header {
    .form-inline {
        .form-control {
            border-color: rgba(225, 225, 225, 0.1);
            padding-left: 20px;
            width: 220px;
            padding-right: 40px;
            background: rgba(225, 225, 225, 0.3);
            color: $black !important;
        }
        .btn {
            border-radius: 0;
            padding: 5px 15px 6px 15px;
            border: 2px solid transparent;
            box-shadow: none;
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
            background: transparent;
            color: $white;
            margin-left: -56px;
            font-size: 15px;
        }
    }
    .dropdown-menu {
        padding: 0;
        border: 0;
    }
}

@media (max-width: 767.98px) and (min-width: 576px) {
    .header .form-inline .search-element {
        position: absolute;
        top: 8px;
        left: 10px;
        right: 10px;
        z-index: 892;
        display: none;
    }
    body.search-show .header .form-inline .search-element {
        display: block;
    }
    .header .navsearch i {
        font-size: 20px;
        color: $white;
    }
    .search-element .form-control {
        float: left;
        width: 100% !important;
        height: 40px;
        margin: 0 auto;
        background: $white !important;
        color: $black;
    }
    .search-show .search-element {
        display: block;
        left: 0 !important;
        top: 0 !important;
        right: 0;
        width: 100%;
        padding: 10px 13px;
    }
    .header {
        .form-inline .form-control::-webkit-input-placeholder {
            color: #76839a;
        }
        .navsearch i {
            font-size: 20px;
            color: $white;
            padding-top: 5px;
        }
        .form-inline .btn {
            color: #46494a !important;
            padding: 8px 15px 6px 15px;
        }
    }
}

@media (max-width: 568px) {
    .page-main .header {
        top: 0px !important;
    }
}

.animated-arrow span {
    &:before,
    &:after {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .animated-arrow span {
        &:before,
        &:after {
            cursor: pointer;
            height: 2px;
            width: 17px;
            background: $default-color;
            position: absolute;
            display: block;
            content: '';
        }
    }
    .animated-arrow span {
        &:before,
        &:after {
            cursor: pointer;
            height: 2px;
            width: 17px;
            background: $default-color;
            position: absolute;
            display: block;
            content: '';
        }
    }
}

.header-style .header-brand {
    margin-left: 0 !important;
}

@media only screen and (max-width: 991px) {
    .page-header {
        padding: 4px;
    }
    .app-header {
        box-shadow: none !important;
        position: fixed !important;
    }
    .page {
        position: inherit;
        background: none;
    }
    body {
        background-color: #f6f6fb;
    }
    .page-main {
        z-index: inherit;
    }
}

@media only screen and (min-width: 768px) {
    .header .form-inline .form-control {
        width: 200px;
    }
}

.hor-header {
    .header-brand1 {
        color: inherit;
        font-size: 1.25rem;
        white-space: nowrap;
        font-weight: 600;
        padding: 0;
        transition: .3s opacity;
        line-height: 2.8rem;
        margin-top: 2px;
    }
}

.header-right-icons {
    .nav-link.icon:hover {
        background: none;
        border-radius: 50px;
    }
    .profile-user:hover {
        box-shadow: none;
    }
}

@media (max-width: 480px) {
    .app-header .header-brand,
    .app-header1 .header-brand {
        min-width: auto;
    }
    .header-right-icons .dropdown .header-toggler {
        margin-top: 9px;
    }
}

@media (max-width: 991px) {
    .header {
        border-bottom: 1px solid $white-2;
        padding-top: 0.95rem;
        padding-bottom: 0.95rem;
    }
}

.header {
    .notifications {
        .dropdown-menu {
            min-width: 18rem;
        }
    }
    .dropdown.message {
        .dropdown-menu {
            min-width: 19rem;
        }
    }
    .dropdown.notifications,
    .dropdown.message {
        .dropdown-item {
            padding: 1rem !important;
        }
    }
    .dropdown-menu {
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .2);
        border: 1px solid $border;
        top: 3.5rem !important;
        .badge {
            padding: 3px 6px;
        }
        .dropdown-item {
            border-bottom: 1px solid $border;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .dropdown-item {
        color: $default-color;
    }
    .notifications-menu,
    .message-menu {
        h5 {
            font-size: 13.5px;
            color: $default-color;
            font-weight: 500;
        }
        span {
            font-size: 12px;
            color: #76839a;
        }
    }
    .dropdown-menu {
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .2);
        border: 1px solid $border;
        .badge {
            padding: 3px 6px;
        }
        .dropdown-item {
            border-bottom: 1px solid $border;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .message-menu .wd-90p {
        width: 90%;
    }
    .profile-1 {
        .dropdown-item {
            padding: .75rem 1.75rem !important;
            .dropdown-icon {
                font-size: 16px;
                color: $primary-1;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    top: 5px;
                    bottom: 0;
                    border-radius: 50%;
                    right: 3px;
                    background: rgba($primary-1, 0);
                }
            }
        }
    }
}

.responsive-navbar {
    .notifications-menu,
    .message-menu {
        h5 {
            font-size: 13.5px;
            color: $default-color;
            font-weight: 500;
        }
        span {
            font-size: 12px;
            color: #76839a;
        }
    }
    .dropdown-menu {
        box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .2);
        border: 1px solid $border;
        .badge {
            padding: 4px 8px;
        }
        .dropdown-item {
            border-bottom: 1px solid $border;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .message-menu .wd-90p {
        width: 90%;
    }
    .profile-1 {
        .dropdown-item {
            padding: .75rem 1.75rem !important;
            .dropdown-icon {
                font-size: 16px;
                color: $primary-1;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    top: 5px;
                    bottom: 0;
                    border-radius: 50%;
                    right: 3px;
                    background: rgba($primary-1, 0);
                }
            }
        }
    }
}

.dropdown .avatar.profile-user {
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
}
@media only screen and (max-width: 460px) {
    .profile-cover__img .profile-img-1>img{
        margin-left: -29px;
    }
}
.app-header, .hor-header{
    .responsive-navbar{
        .notifications-menu, .message-menu{
            .dropdown-item h5, .dropdown-item span{
                white-space: normal !important;
            }
        }
        .notifications-menu .wd-80p{
            width: 80%;
        }
    }
    .profile-1{
        .dropdown-item{
            white-space: normal;
        }
    }
}

.header{
    .header-dropdown-list {
        height: 300px;
    }
    .notifications-menu{
        height: 300px;
    }
    .message-menu-scroll{
        height: 290px;
    }
} 

.dropdown-footer{
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
}
.shopping-cart, .notifications, .message{
    .ps__rail-y{
        height: 234px !important;
    }
    .ps__thumb-y{
        height: 118px !important;
        background-color: transparent ;
    }
    .ps__rail-y:hover>.ps__thumb-y, .ps__rail-y:focus>.ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
        background-color: transparent;
    }
    .header-dropdown-list{
        .wd-50p{
            width: 50% !important;
        }
    }
}

.main-header-center{
    .form-control.dropdown-toggle{
        -webkit-user-select: text;
        user-select: text;
    }
}