div.dt-datetime {
    position: absolute;
    background-color: white;
    z-index: 6;
    border: 1px solid $border;
    box-shadow: 0px 16px 18px rgb(104 113 123 / 20%);
    padding: 0 20px 6px 20px;
    width: 300px;
    border-radius: 5px;
    margin-top: 5px;
}
div.dt-datetime.inline {
    position: relative;
    box-shadow: none;
}
div.dt-datetime div.dt-datetime-title {
    text-align: center;
    padding: 14px 3px 15px;
}
div.dt-datetime div.dt-datetime-buttons {
    text-align: center;
}
div.dt-datetime div.dt-datetime-buttons a {
    display: inline-block;
    padding: 0 0.5em 0.5em 0.5em;
    margin: 0;
    font-size: 0.9em;
}
div.dt-datetime div.dt-datetime-buttons a:hover {
    text-decoration: underline;
}
div.dt-datetime table {
    border-spacing: 0;
    margin: 12px 0;
    width: 100%;
}
div.dt-datetime table.dt-datetime-table-nospace {
    margin-top: -12px;
}
div.dt-datetime table th {
    font-size: 13px;
    color: $black;
    font-weight: normal;
    width: 14.285714286%;
    padding: 0 7px 15px 7px;
    text-align: center;
}
div.dt-datetime table td {
    font-size: 0.9em;
    color: #444;
    padding: 0;
}
div.dt-datetime table td.selectable {
    text-align: center;
    // background: $background;
}
div.dt-datetime table td.selectable.disabled {
    color: #aaa;
    background: white;
}
div.dt-datetime table td.selectable.disabled button:hover {
    color: #aaa;
    background: white;
}
div.dt-datetime table td.selectable.now {
    background-color: $primary-1;
    color: $white;
}
div.dt-datetime table td.selectable.now button {
    font-weight: bold;
}
div.dt-datetime table td.selectable.selected button {
    background: $primary-09;
    color: white;
    border-radius: 2px;
}
div.dt-datetime table td.selectable button:hover {
    background: $primary-1;
    color: white;
    border-radius: 2px;
}
div.dt-datetime table td.dt-datetime-week {
    font-size: 0.7em;
}
div.dt-datetime table button {
    width: 100%;
    box-sizing: border-box;
    border: none;
    background: transparent;
    font-size: inherit;
    color: inherit;
    text-align: center;
    padding: 7px 10px;
    cursor: pointer;
    margin: 0;
}
div.dt-datetime table button span {
    display: inline-block;
    min-width: 14px;
    text-align: right;
}
div.dt-datetime table.weekNumber th {
    width: 12.5%;
}
div.dt-datetime div.dt-datetime-calendar table {
    margin-top: 0;
}
div.dt-datetime div.dt-datetime-label {
    position: relative;
    display: inline-block;
    // height: 30px;
    padding: 5px 6px;
    border: 1px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
}
div.dt-datetime div.dt-datetime-label:hover {
    border: 1px solid $border;
    border-radius: 2px;
    background-color: $primary-01;
}
div.dt-datetime div.dt-datetime-label select {
    position: absolute;
    top: 6px;
    left: 0;
    cursor: pointer;
    opacity: 0;
}
div.dt-datetime.horizontal {
    width: 550px;
}
div.dt-datetime.horizontal div.dt-datetime-date,
div.dt-datetime.horizontal div.dt-datetime-time {
    width: 48%;
}
div.dt-datetime.horizontal div.dt-datetime-time {
    margin-left: 4%;
}
div.dt-datetime div.dt-datetime-date {
    position: relative;
    float: left;
    width: 100%;
}
div.dt-datetime div.dt-datetime-time {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
}
div.dt-datetime div.dt-datetime-time > span {
    vertical-align: middle;
}
div.dt-datetime div.dt-datetime-time th {
    text-align: left;
}
div.dt-datetime div.dt-datetime-time div.dt-datetime-timeblock {
    display: inline-block;
    vertical-align: middle;
}
div.dt-datetime div.dt-datetime-iconLeft,
div.dt-datetime div.dt-datetime-iconRight,
div.dt-datetime div.dt-datetime-iconUp,
div.dt-datetime div.dt-datetime-iconDown {
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;
    overflow: hidden;
    box-sizing: border-box;
}
div.dt-datetime div.dt-datetime-iconLeft:hover,
div.dt-datetime div.dt-datetime-iconRight:hover,
div.dt-datetime div.dt-datetime-iconUp:hover,
div.dt-datetime div.dt-datetime-iconDown:hover {
    border: 1px solid $border;
    border-radius: 2px;
    background-color: #f0f0f0;
    opacity: 0.6;
}
div.dt-datetime div.dt-datetime-iconLeft button,
div.dt-datetime div.dt-datetime-iconRight button,
div.dt-datetime div.dt-datetime-iconUp button,
div.dt-datetime div.dt-datetime-iconDown button {
    border: none;
    background: transparent;
    text-indent: 30px;
    height: 100%;
    width: 100%;
    cursor: pointer;
}
div.dt-datetime div.dt-datetime-iconLeft {
    position: absolute;
    top: 15px;
    left: 0px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==");
}
div.dt-datetime div.dt-datetime-iconRight {
    position: absolute;
    top: 15px;
    right: 0px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=");
}
div.dt-datetime div.dt-datetime-iconUp {
    height: 20px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAALCAMAAABf9c24AAAAFVBMVEX///99fX1+fn57e3t6enoAAAAAAAC73bqPAAAABnRSTlMAYmJkZt92bnysAAAAL0lEQVR4AWOgJmBhxCvLyopHnpmVjY2VCadeoCxIHrcsWJ4RlyxCHlMWCTBRJxwAjrIBDMWSiM0AAAAASUVORK5CYII=");
}
div.dt-datetime div.dt-datetime-iconDown {
    height: 20px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAALCAMAAABf9c24AAAAFVBMVEX///99fX1+fn57e3t6enoAAAAAAAC73bqPAAAABnRSTlMAYmJkZt92bnysAAAAMElEQVR4AWOgDmBiRQIsmPKMrGxQgJDFlEfIYpoPk8Utz8qM232MYFfhkQfKUg8AANefAQxecJ58AAAAAElFTkSuQmCC");
}
div.dt-datetime-error {
    clear: both;
    padding: 0 1em;
    max-width: 240px;
    font-size: 11px;
    line-height: 1.25em;
    text-align: center;
    color: #b11f1f;
}

.datetimetable{
    input{
        border: 1px solid $border;
        padding: 7px;
        &:focus-visible {
            outline: none;
        }
        &::placeholder{
            color: $black-7;
        }
    }
}