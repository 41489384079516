/*--------- Width--------------*/

.w-1 {
    width: 10% !important;
}

.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}

.w-60 {
    width: 60% !important;
}

.w-45 {
    width: 45% !important;
}

.w-50 {
    width: 50% !important;
}

.w-65 {
    width: 65% !important;
}

.w-70 {
    width: 70% !important;
}

.w-75 {
    width: 75% !important;
}

.w-80 {
    width: 80% !important;
}

.w-100 {
    width: 100% !important;
}

.w-260 {
    width: 260px !important;
}

.w-337 {
    width: 337px !important;
}

.w-auto {
    width: auto !important;
}

.w-0 {
    width: 0 !important;
}

.w-1 {
    width: 0.25rem !important;
}

.w-2 {
    width: 0.5rem !important;
}

.w-3 {
    width: 0.75rem !important;
}

.w-4 {
    width: 1rem !important;
}

.w-5 {
    width: 1.5rem !important;
}

.w-6 {
    width: 2rem !important;
}

.w-7 {
    width: 3rem !important;
}

.w-8 {
    width: 4rem !important;
}

.w-9 {
    width: 6rem !important;
}

.w-90 {
    width: 90% !important;
}

.mw-100 {
    max-width: 100% !important;
}

.w-100p {
    width: 100px !important;
}

.w-30p {
    width: 30px !important;
}

@media (max-width: 576px) {
    .w-sm-50 {
        width: 50% !important;
    }
    .w-sm-60 {
        width: 60% !important;
    }
}