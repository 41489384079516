div {
    &.dt-autofill-handle {
      position: absolute;
      height: 8px;
      width: 8px;
      z-index: 99;
      box-sizing: border-box;
      background: $primary-1;
      cursor: pointer;
    }
  
    &.dtk-focus-alt div.dt-autofill-handle {
      background: #ff8b33;
    }
  
    &.dt-autofill-select {
      position: absolute;
      z-index: 1001;
      background-color: $primary-1;
      background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.5) 10px);
  
      &.top, &.bottom {
        height: 3px;
        margin-top: -1px;
      }
  
      &.left, &.right {
        width: 3px;
        margin-left: -1px;
      }
    }
  
    &.dt-autofill-list {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 500px;
      margin-left: -250px;
      background-color: white;
      border-radius: 6px;
      // box-shadow: 0 0 5px #555;
      // border: 2px solid #444;
      z-index: 99;
      box-sizing: border-box;
      padding: 1em 0em;
  
      ul {
        display: table;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
  
        li {
          display: table-row;
  
          &:last-child div {
            &.dt-autofill-question, &.dt-autofill-button {
              border-bottom: none;
            }
          }
  
          &:hover {
            background-color: #f6f6fb;
          }
        }
      }
  
      div {
        &.dt-autofill-question {
          display: table-cell;
          padding: 0.5em 1.5rem;
          border-bottom: 1px dotted $border;
  
          input[type="number"] {
            padding: 6px;
            width: 30px;
            margin: -2px 0;
          }
        }
  
        &.dt-autofill-button {
          display: table-cell;
          padding: 0.5em 0;
          border-bottom: 1px dotted $border;
        }
      }
    }
  
    &.dt-autofill-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
      z-index: 99;
    }
  
    &.dt-autofill-list div.dt-autofill-question input[type="number"] {
      padding: 6px;
      width: 60px;
      margin: -2px 0;
    }
  }

#enable-autofill_wrapper{
  .dt-buttons.btn-group.flex-wrap{
    top: inherit !important;
    left: inherit !important;
  }
}